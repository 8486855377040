import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const createManager = createAsyncThunk(
  "manager/create",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    const formData = new FormData();
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("confirm_password", data.confirm_password);
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("phone_number", data.phone_number);
    formData.append("u_did", data.u_did);

    if (data && data.is_driver !== undefined && data.is_driver !== "") {
      formData.append("is_driver", data.is_driver);
    }

    /* if (data.avatar) {
      formData.append("avatar", data.avatar);
    }
 */
    const requestOptions = {
      method: "POST",
      body: formData,
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(`${apiUrl}/add_manager`, requestOptions);

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Registration failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const createManagerSlice = createSlice({
  name: "create",
  initialState: {
    status: "idle",
    error: null,
  },
  reducers: {
    resetCreateManagerState(state) {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createManager.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createManager.fulfilled, (state, action) => {
        state.status = "success";
        state.error = null;
      })
      .addCase(createManager.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetCreateManagerState } = createManagerSlice.actions;
export default createManagerSlice.reducer;
