import classes from "./Pagination.module.css";
import { setCurrentPage } from "../../../redux/globalFnSlice";
import { useDispatch, useSelector } from "react-redux";

export function Pagination({ totalPages, filteredPages, isFiltered }) {
  const dispatch = useDispatch();
  const { currentPage } = useSelector((state) => state.global);
  const handleNextPage = () => {
    dispatch(setCurrentPage(Math.min(currentPage + 1, totalDisplayedPages)));
  };

  const handlePrevPage = () => {
    dispatch(setCurrentPage(Math.max(currentPage - 1, 1)));
  };

  const handlePageClick = (page) => {
    dispatch(setCurrentPage(page));
  };

  const totalDisplayedPages = isFiltered ? filteredPages : totalPages;

  const renderPageButton = (page) => (
    <button
      key={page}
      className={currentPage === page ? classes.activePage : classes.firstPage}
      onClick={() => handlePageClick(page)}
    >
      {page}
    </button>
  );

  const renderPages = () => {
    if (totalDisplayedPages <= 4) {
      // Function for displaying all pages if 4 or fewer.
      return Array.from({ length: totalDisplayedPages }, (_, i) =>
        renderPageButton(i + 1)
      );
    } else {
      // Function for displaying the first 4 pages and the last page with three dots before the last page.
      return (
        <>
          {Array.from({ length: 4 }, (_, i) => renderPageButton(i + 1))}
          {currentPage > 4 && currentPage !== totalDisplayedPages && (
            <button className={classes.activePage}>{currentPage}</button>
          )}
          {currentPage < totalDisplayedPages - 1 && totalDisplayedPages > 5 && (
            <p>...</p>
          )}
          {currentPage === totalDisplayedPages && totalDisplayedPages > 6 && (
            <p>...</p>
          )}
          {currentPage === totalDisplayedPages &&
            totalDisplayedPages > 5 &&
            renderPageButton(currentPage - 1)}
          {renderPageButton(totalDisplayedPages)}
        </>
      );
    }
  };

  return (
    <div className={classes.pagination}>
      <button
        onClick={handlePrevPage}
        disabled={currentPage === 1}
        className={classes.prevBtn}
      >
        {"<"}
      </button>
      {renderPages()}
      <button
        onClick={handleNextPage}
        disabled={currentPage === totalDisplayedPages}
        className={classes.nextBtn}
      >
        {">"}
      </button>
    </div>
  );
}
