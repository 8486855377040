import { combineReducers } from "@reduxjs/toolkit";
import GlobalFnSlice from "./globalFnSlice";
import createDepartment from "./department/createDepartment";
import getDepartments from "./department/getDepartments";
import getAllDepartments from "./department/getAllDeaprtments";
import deleteDepartment from "./department/deleteDepartment";
import updateDepartment from "./department/updateDepartment";
import createCompany from "./company/createCompany";
import getCompanies from "./company/getCompanies";
import updateCompany from "./company/updateCompany";
import deleteCompany from "./company/deleteCompany";
import search from "./search/search";
import login from "./login/login";
import register from "./register/register";
import getUsers from "./users/getUsers";
import updateUser from "./users/updateUser";
import deleteUser from "./users/deleteUser";
import createManager from "./managers/createManager";

const rootReducer = combineReducers({
  // Global state slice
  global: GlobalFnSlice,

  // Department slices.
  createDepartment: createDepartment,
  getDepartments: getDepartments,
  getAllDepartments: getAllDepartments,
  deleteDepartment: deleteDepartment,
  updateDepartment: updateDepartment,

  // Company slices.
  createCompany: createCompany,
  getCompanies: getCompanies,
  updateCompany: updateCompany,
  deleteCompany: deleteCompany,

  //
  search: search,

  // Register a new super admin user.
  register: register,

  // User slices
  user: login,
  getUsers: getUsers,
  updateUser: updateUser,
  deleteUser: deleteUser,

  // Slice for creating a user.
  createManager: createManager,
});

export default rootReducer;
