import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setCurrentPage,
  setIsEditMode,
  setIsErrorMsgShown,
  setIsSuccessMsgShown,
} from "../../redux/globalFnSlice";

// Custom Hook to handle status effects.
export function useStatusEffect({
  status,
  successMessage,
  errorMessage,
  onSuccess,
  onError,
  resetStatus,
  reFetch,
  onPage,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === "success") {
      dispatch(reFetch({ page: onPage }));
      dispatch(setCurrentPage(onPage));
      dispatch(onSuccess());
      dispatch(setIsSuccessMsgShown(successMessage));
      dispatch(setIsEditMode());
      setTimeout(() => {
        dispatch(resetStatus());
        dispatch(setIsSuccessMsgShown(""));
      }, 2000);
    } else if (status === "error") {
      dispatch(onError());
      dispatch(setIsErrorMsgShown(errorMessage));
      dispatch(setIsEditMode());
      setTimeout(() => {
        dispatch(resetStatus());
        dispatch(setIsErrorMsgShown(""));
      }, 2000);
    }
  }, [status]);
}
