// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchableDropdown_dropdown__uZ4dL {
  position: relative;
  width: 100%;
}
.SearchableDropdown_dropdown__uZ4dL label {
  color: rgba(27, 31, 59, 0.65);
  width: 100%;
  display: flex;
}

.SearchableDropdown_dropdownHeader__De48V {
  padding: 10px 0;
  cursor: pointer;
  background-color: white;
  font-size: 1rem;
  color: #121b3f;
}

.SearchableDropdown_dropdownBody__6HnZM {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background-color: white;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.SearchableDropdown_dropdownBody__6HnZM input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

.SearchableDropdown_dropdownList__w9e\\+Z {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: calc(100vh - 80vh);
  overflow-y: auto;
}

.SearchableDropdown_dropdownItem__xM3EC {
  padding: 10px;
  cursor: pointer;
}

.SearchableDropdown_dropdownItem__xM3EC:hover {
  background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/components/searchableDropdown/SearchableDropdown.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,6BAA6B;EAC7B,WAAW;EACX,aAAa;AACf;;AAEA;EACE,eAAe;EACf,eAAe;EACf,uBAAuB;EACvB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;EACP,QAAQ;EACR,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,6BAA6B;EAC7B,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;EACV,8BAA8B;EAC9B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".dropdown {\r\n  position: relative;\r\n  width: 100%;\r\n}\r\n.dropdown label {\r\n  color: rgba(27, 31, 59, 0.65);\r\n  width: 100%;\r\n  display: flex;\r\n}\r\n\r\n.dropdownHeader {\r\n  padding: 10px 0;\r\n  cursor: pointer;\r\n  background-color: white;\r\n  font-size: 1rem;\r\n  color: #121b3f;\r\n}\r\n\r\n.dropdownBody {\r\n  position: absolute;\r\n  top: 100%;\r\n  left: 0;\r\n  right: 0;\r\n  border: 1px solid #ccc;\r\n  background-color: white;\r\n  z-index: 10;\r\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.dropdownBody input {\r\n  width: 100%;\r\n  padding: 10px;\r\n  box-sizing: border-box;\r\n  border: none;\r\n  border-bottom: 1px solid #ccc;\r\n  outline: none;\r\n}\r\n\r\n.dropdownList {\r\n  list-style: none;\r\n  margin: 0;\r\n  padding: 0;\r\n  max-height: calc(100vh - 80vh);\r\n  overflow-y: auto;\r\n}\r\n\r\n.dropdownItem {\r\n  padding: 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.dropdownItem:hover {\r\n  background-color: #f0f0f0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `SearchableDropdown_dropdown__uZ4dL`,
	"dropdownHeader": `SearchableDropdown_dropdownHeader__De48V`,
	"dropdownBody": `SearchableDropdown_dropdownBody__6HnZM`,
	"dropdownList": `SearchableDropdown_dropdownList__w9e+Z`,
	"dropdownItem": `SearchableDropdown_dropdownItem__xM3EC`
};
export default ___CSS_LOADER_EXPORT___;
