// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteCompany_container__8WCKl {
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  min-width: 350px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.DeleteCompany_header__mnR4W {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DeleteCompany_header__mnR4W h4 {
  margin: 0;
}

.DeleteCompany_header__mnR4W span {
  cursor: pointer;
}

.DeleteCompany_orgName__B1LNr {
  color: #3082f6;
  font-weight: 800;
}

.DeleteCompany_container__8WCKl p {
  margin: 0;
  color: rgba(27, 31, 59, 0.65);
}

.DeleteCompany_buttons__vi6cB {
  display: flex;
  width: 100%;
  gap: 16px;
}

.DeleteCompany_cancelBtn__DVWHV {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  padding: 0 16px;
  border: 1px solid #a4a5b1;
  background-color: transparent;
  cursor: pointer;
  color: #676c7e;
}

.DeleteCompany_confirmBtn__VyUXV {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  padding: 0 16px;
  border: none;
  background-color: #3082f6;
  color: white;
  cursor: pointer;
}

.DeleteCompany_confirmBtn__VyUXV:disabled {
  background-color: #8cb6f1;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/deleteCompany/DeleteCompany.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,WAAW;EACX,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,6BAA6B;EAC7B,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\r\n  padding: 2rem;\r\n  background-color: white;\r\n  border-radius: 8px;\r\n  min-width: 350px;\r\n  max-width: 450px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 32px;\r\n}\r\n\r\n.header {\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n}\r\n\r\n.header h4 {\r\n  margin: 0;\r\n}\r\n\r\n.header span {\r\n  cursor: pointer;\r\n}\r\n\r\n.orgName {\r\n  color: #3082f6;\r\n  font-weight: 800;\r\n}\r\n\r\n.container p {\r\n  margin: 0;\r\n  color: rgba(27, 31, 59, 0.65);\r\n}\r\n\r\n.buttons {\r\n  display: flex;\r\n  width: 100%;\r\n  gap: 16px;\r\n}\r\n\r\n.cancelBtn {\r\n  width: 100%;\r\n  height: 44px;\r\n  border-radius: 12px;\r\n  padding: 0 16px;\r\n  border: 1px solid #a4a5b1;\r\n  background-color: transparent;\r\n  cursor: pointer;\r\n  color: #676c7e;\r\n}\r\n\r\n.confirmBtn {\r\n  width: 100%;\r\n  height: 44px;\r\n  border-radius: 12px;\r\n  padding: 0 16px;\r\n  border: none;\r\n  background-color: #3082f6;\r\n  color: white;\r\n  cursor: pointer;\r\n}\r\n\r\n.confirmBtn:disabled {\r\n  background-color: #8cb6f1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DeleteCompany_container__8WCKl`,
	"header": `DeleteCompany_header__mnR4W`,
	"orgName": `DeleteCompany_orgName__B1LNr`,
	"buttons": `DeleteCompany_buttons__vi6cB`,
	"cancelBtn": `DeleteCompany_cancelBtn__DVWHV`,
	"confirmBtn": `DeleteCompany_confirmBtn__VyUXV`
};
export default ___CSS_LOADER_EXPORT___;
