import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (data = {}, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    const queryParams = new URLSearchParams();

    // Append department filter if present and not empty
    if (data && data.company_name !== undefined && data.company_name !== "") {
      queryParams.append("company_name", data.company_name);
    }

    if (data && data.user_type !== undefined && data.user_type !== "") {
      queryParams.append("user_type", data.user_type);
    }

    if (
      data &&
      data.department_name !== undefined &&
      data.department_name !== ""
    ) {
      queryParams.append("department_name", data.department_name);
    }

    if (data && data.driver_name !== undefined && data.driver_name !== "") {
      queryParams.append("driver_name", data.driver_name);
    }

    if (data && data.email !== undefined && data.email !== "") {
      queryParams.append("email", data.email);
    }

    if (data && data.page) queryParams.append("page", data.page);
    if (data && data.per_page) queryParams.append("per_page", data.per_page);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apiUrl}/get_all_users?${queryParams.toString()}`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        let errorText = "";
        try {
          const errorText = await response.text();
          const errorObj = JSON.parse(errorText);
          errorMessage = errorObj.error || errorMessage;
        } catch (e) {
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetching users failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const fetchUsersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    total_count: null,
    filtered_count: null,
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
  },
  reducers: {
    resetFetchUsersState(state) {
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload.data;
        state.total_count = action.payload.total_count;
        state.filtered_count = action.payload.filtered_count;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.users = [];
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { resetFetchUsersState } = fetchUsersSlice.actions;
export default fetchUsersSlice.reducer;
