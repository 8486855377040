import { useEffect, useState } from "react";
import classes from "./UsersTableElements.module.css";
import editIcon from "../../../images/editIcon.svg";
import deleteIcon from "../../../images/deleteIcon.svg";
import {
  setIsDeleteUserModalShown,
  setIsEditUserModalShown,
} from "../../../redux/globalFnSlice";
import { useDispatch } from "react-redux";
import translations from "../../../en.json";

export function UsersTableElements({
  id,
  firstname,
  lastname,
  company_name,
  department,
  role,
  data,
  email,
}) {
  const [userRole, setUserRole] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (role === 1) {
      setUserRole("Manager");
    } else if (role === 2) {
      setUserRole("Driver");
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.tableElement}>{id}</div>
      <div className={classes.tableElement}>{firstname}</div>
      <div className={classes.tableElement}>{lastname}</div>
      <div className={classes.tableElement}>{email}</div>
      <div className={classes.tableElement}>{company_name}</div>
      <div className={classes.tableElement}>{department}</div>
      <div className={classes.tableElement}>{userRole}</div>
      <div>
        <img
          src={editIcon}
          alt={translations.uiTableElements.editIconAlt}
          onClick={() => dispatch(setIsEditUserModalShown(data))}
        />
      </div>
      <div>
        <img
          src={deleteIcon}
          alt={translations.uiTableElements.deleteIconAlt}
          onClick={() => dispatch(setIsDeleteUserModalShown(data))}
        />
      </div>
    </div>
  );
}
