import classes from "./Header.module.css";
import SearchIcon from "../../images/header/MagnifyingGlass.svg";
import NotificationIcon from "../../images/header/NotificationIcon.svg";
import EnglishFlag from "../../images/header/EnglishFlag.svg";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "../../redux/search/search";
import translations from "../../en.json";
import { setCurrentPage } from "../../redux/globalFnSlice";

export function Header() {
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const searchQuery = useSelector((state) => state.search.query);

  // Update local query state when Redux state changes
  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setQuery(value);
    dispatch(setSearchQuery(value)); // Dispatch the action to set the search query
    dispatch(setCurrentPage(1));
  };

  const headerText = translations.header;

  return (
    <div className={classes.container}>
      <div className={classes.headerContent}>
        <div className={classes.search}>
          <input
            type="text"
            id="search"
            placeholder={headerText.search}
            value={query}
            onChange={handleSearch}
          />
          <label htmlFor="search">
            <img src={SearchIcon} alt={headerText.searchIconAlt} />
          </label>
        </div>
        <div className={classes.headerIcons}>
          <span>
            <img src={NotificationIcon} alt={headerText.notificationIconAlt} />
          </span>
          <div className={classes.flagContainer}>
            <img src={EnglishFlag} alt={headerText.englishFlagAlt} />
          </div>
        </div>
      </div>
    </div>
  );
}
