import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const deleteDepartment = createAsyncThunk(
  "department/deleteDepartment",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    const queryParams = new URLSearchParams({
      department_id: data.department_id,
    }).toString();

    const requestOptions = {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(
        `${apiUrl}/delete_department_super?${queryParams}`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        try {
          const error = await response.json();
          errorMessage = error.msg || errorMessage;
        } catch (e) {
          errorMessage = await response.text();
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Deleting department failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const deleteDepartmentSlice = createSlice({
  name: "department",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    message: null,
  },
  reducers: {
    resetDeleteDepartmentStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteDepartment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.message = action.payload.msg;
        state.status = "success";
        state.error = null;
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetDeleteDepartmentStatus } = deleteDepartmentSlice.actions;
export default deleteDepartmentSlice.reducer;
