import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import { Header } from "./components/header/Header";
import { Organizations } from "./components/organizations/Organizations";
import { Sidebar } from "./components/sidebar/Sidebar";
import { Users } from "./components/users/Users";
import { AddOrganization } from "./components/modals/addOrganization/AddOrganization";
import { SuccessMsg } from "./components/successMsg/SuccessMsg";
import { ErrorMsg } from "./components/errorMsg/ErrorMsg";
import { DeleteOrganization } from "./components/modals/deleteOrganization/DeleteOrganization";
import { AddCompany } from "./components/modals/addCompany/AddCompany";
import { DeleteCompany } from "./components/modals/deleteCompany/DeleteCompany";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  setIsOrganizationsClicked,
  setIsUsersClicked,
} from "./redux/globalFnSlice";
import { DepartmentsModal } from "./components/modals/departmentsModal/DepartmentsModal";
import { Login } from "./pages/login/Login";
import { Register } from "./pages/register/Register";
import { EditUser } from "./components/modals/editUser/EditUser";
import { DeleteUser } from "./components/modals/deleteUser/DeleteUser";
import { AddManager } from "./components/modals/addManager/AddManager";

function App() {
  const {
    isOrganizationsClicked,
    isUsersClicked,
    isModalsBgShown,
    isAddOrgModalShown,
    isSuccessMsgShown,
    successMsg,
    isErrorMsgShown,
    errorMsg,
    isDeleteOrgModalShown,
    isAddCompanyModalShown,
    isDeleteCompanyModalShown,
    isDepartmentsModalShown,
    isAddUserModalShown,
    isEditUserModalShown,
    isDeleteUserModalShown,
    isAddManager,
  } = useSelector((state) => state.global);

  const userState = useSelector((state) => state.user);
  const token = userState.token;
  const location = useLocation();
  const dispatch = useDispatch();

  // State to manage if the path is checked
  const [isPathChecked, setIsPathChecked] = useState(false);

  useEffect(() => {
    if (location.pathname === "/organizations") {
      dispatch(setIsOrganizationsClicked());
    } else if (location.pathname === "/users") {
      dispatch(setIsUsersClicked());
    }
    setIsPathChecked(true);
  }, [location.pathname]);

  if (!isPathChecked) {
    // Return null or a loader while the path is being checked
    return null;
  }

  const isAuthRoute =
    location.pathname === "/login" || location.pathname === "/register";

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
      {!isAuthRoute && token ? (
        <div className="App">
          <Sidebar />
          <div className="content">
            <Header />
            {isOrganizationsClicked && <Organizations />}
            {isUsersClicked && <Users />}
          </div>
          {isModalsBgShown && (
            <div className="modalBg">
              {isAddOrgModalShown && <AddOrganization />}
              {isSuccessMsgShown && <SuccessMsg msg={successMsg} />}
              {isErrorMsgShown && <ErrorMsg msg={errorMsg} />}
              {isDeleteOrgModalShown && <DeleteOrganization />}
              {isAddCompanyModalShown && <AddCompany />}
              {isDeleteCompanyModalShown && <DeleteCompany />}
              {isDepartmentsModalShown && <DepartmentsModal />}
              {isAddUserModalShown && <AddManager />}
              {isEditUserModalShown && <EditUser />}
              {isDeleteUserModalShown && <DeleteUser />}
              {isAddManager && <AddManager />}
            </div>
          )}
        </div>
      ) : (
        !isAuthRoute && <Navigate to="/login" />
      )}
    </>
  );
}

export default App;
