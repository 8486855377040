import React, { useEffect, useState } from "react";
import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useDispatch } from "react-redux";
import { setAddressData } from "../../redux/globalFnSlice";

const MapboxComponent = ({ address, updateAddress }) => {
  const [coordinates, setCoordinates] = useState(null);
  const [viewState, setViewState] = useState({
    latitude: 37.7749,
    longitude: -122.4194,
    zoom: 15,
  });

  const mapboxToken =
    "pk.eyJ1IjoiYm96aWRhcmszNSIsImEiOiJjbTFxNTdzMWUwOWZwMmpzYjN4eHF5Zmt5In0.fVeom9WzttFClBuhzNSjKg";

  const dispatch = useDispatch();

  const geocodeAddress = async () => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          address || "San Francisco"
        )}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();
      const addressData = data.features;
      dispatch(setAddressData({ addressData }));

      const [lng, lat] = data.features[0].center;
      setCoordinates({ lat, lng });
      setViewState((prevState) => ({
        ...prevState,
        latitude: lat,
        longitude: lng,
      }));
    } catch (error) {
      console.error("Error fetching the coordinates:", error);
    }
  };

  const reverseGeocodeCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxToken}`
      );
      const data = await response.json();
      const address = data.features[0].place_name;
      updateAddress(address);
    } catch (error) {
      console.error("Error fetching the address:", error);
    }
  };

  useEffect(() => {
    geocodeAddress();
  }, [address]);

  const handleMarkerDragEnd = (event) => {
    const lat = event.lngLat.lat;
    const lng = event.lngLat.lng;
    setCoordinates({ lat, lng });
    reverseGeocodeCoordinates(lat, lng);
  };

  return (
    <div>
      {coordinates && (
        <Map
          {...viewState}
          onMove={(evt) => setViewState(evt.viewState)}
          style={{ width: "100%", height: "200px" }}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxAccessToken={mapboxToken}
        >
          <Marker
            latitude={coordinates.lat}
            longitude={coordinates.lng}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
          />
        </Map>
      )}
    </div>
  );
};

export default MapboxComponent;
