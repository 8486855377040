import classes from "./AddOrganization.module.css";
import Xicon from "../../../images/Xicon.svg";
import {
  setIsAddOrgModalShown,
  setIsEditMode,
} from "../../../redux/globalFnSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { createDepartment } from "../../../redux/department/createDepartment";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";
import { updateDepartment } from "../../../redux/department/updateDepartment";
import SearchableDropdown from "../../searchableDropdown/SearchableDropdown";
import MapboxComponent from "../../mapBox/MapBox";
import translations from "../../../en.json";
import { fetchCompanies } from "../../../redux/company/getCompanies";

export function AddOrganization() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const { isEditMode, selectedData } = useSelector((state) => state.global);
  const companiesState = useSelector((state) => state.getCompanies);
  const createDepartmentState = useSelector((state) => state.createDepartment);
  const updateDepartmentState = useSelector((state) => state.updateDepartment);

  const [formData, setFormData] = useState({
    d_name: "",
    d_address: "",
    d_phonenumber: "",
    d_cid: "",
  });

  useEffect(() => {
    if (companiesState?.companies?.length === 0) {
      dispatch(fetchCompanies());
    }
  }, [companiesState.companies.company_names, dispatch]);

  function toggleModal() {
    dispatch(setIsAddOrgModalShown());
    dispatch(setIsEditMode(false));
  }

  // Handle form input changes.
  const handleInputChange = (e) => {
    const { id, value } = e.target;

    let newValue = value;

    // Check if the input is the phone number field
    if (id === "d_phonenumber") {
      // Remove all dashes
      newValue = value.replace(/-/g, "");

      // Only allow '+' as the first character and digits after it
      if (newValue.length > 0) {
        newValue =
          newValue[0] === "+"
            ? "+" + newValue.slice(1).replace(/\D/g, "")
            : newValue.replace(/\D/g, "");
      }
    }

    setFormData({
      ...formData,
      [id]: newValue,
    });
  };

  // If isEditMode is true then populate the fields with the data from selectedData.
  useEffect(() => {
    if (isEditMode && selectedData) {
      setFormData({
        d_name: selectedData.d_name || "",
        d_phonenumber: selectedData.d_phonenumber || "",
        d_address: selectedData.d_address || "",
      });
    } else if (!isEditMode && selectedData) {
      setFormData({ d_cid: selectedData.c_id });
    }
  }, [isEditMode, selectedData]);

  // Function for submitting the form.
  function handleSubmit(e) {
    e.preventDefault();
    if (isEditMode) {
      dispatch(
        updateDepartment({
          d_id: selectedData.d_id,
          ...formData,
        })
      );
    } else {
      dispatch(createDepartment(formData));
    }
  }

  // Checks for the submit button disabling.
  const isStatusLoading =
    createDepartmentState.status === "loading" ||
    updateDepartmentState.status === "loading";
  const isEditModeFields =
    formData.d_name === "" ||
    formData.d_phonenumber === "" ||
    formData.d_address === "" ||
    formData.d_cid === "";

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  function handleDropdownSelect({ target }) {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  }

  //Update address
  const changeAddress = (newAddress) => {
    setFormData((prevData) => ({
      ...prevData,
      d_address: newAddress,
    }));
  };

  const modalText = translations.addOrganizationModal;

  const headerText = isEditMode
    ? modalText.headerTextEdit
    : modalText.headerTextAdd;

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>{headerText}</h3>
        <span onClick={toggleModal}>
          <img src={Xicon} alt={modalText.closeModalIconAlt} />
        </span>
      </div>
      <form className={classes.addOrgForm} onSubmit={handleSubmit}>
        <div className={classes.elements}>
          <div className={classes.formElement}>
            <label htmlFor="d_name">{modalText.form.departmentName}</label>
            <input
              type="text"
              id="d_name"
              value={formData.d_name}
              required
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.formElement}>
            <label htmlFor="d_phonenumber">{modalText.form.phoneNumber}</label>
            <input
              type="tel"
              id="d_phonenumber"
              value={formData.d_phonenumber}
              required
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className={classes.elements}>
          <div className={classes.formElement}>
            <label htmlFor="d_address">{modalText.form.address}</label>
            <input
              type="text"
              id="d_address"
              value={formData.d_address}
              required
              onChange={handleInputChange}
            />
          </div>
          {!isEditMode && (
            <div className={classes.formElement}>
              {companiesState.companies.company_names && (
                <SearchableDropdown
                  idForLabel={modalText.form.selectCompany}
                  options={
                    companiesState.companies.company_names &&
                    companiesState.companies.company_names.map((company) => ({
                      value: company.c_id,
                      label: company.c_name,
                      companyID: company.c_id,
                    }))
                  }
                  placeholder={modalText.form.selectName}
                  onSelect={(value) =>
                    handleDropdownSelect({ target: { name: "d_cid", value } })
                  }
                  value={formData.d_cid}
                />
              )}
            </div>
          )}
        </div>
        <MapboxComponent
          address={formData.d_address}
          updateAddress={changeAddress}
        />
        <button
          className={classes.saveBtn}
          type="submit"
          disabled={isEditModeFields || isStatusLoading}
        >
          {isStatusLoading ? <LoadingSpinner /> : modalText.saveBtn}
        </button>
      </form>
    </div>
  );
}
