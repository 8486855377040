import classes from "./DeleteCompany.module.css";
import closeIcon from "../../../images/Xicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setIsDeleteCompanyModalShown } from "../../../redux/globalFnSlice";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { useRef } from "react";
import { deleteCompany } from "../../../redux/company/deleteCompany";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";
import translations from "../../../en.json";

export function DeleteCompany() {
  const modalRef = useRef();
  const dispatch = useDispatch();
  const { selectedData } = useSelector((state) => state.global);
  const deleteCompanyState = useSelector((state) => state.deleteCompany);

  function toggleModal() {
    dispatch(setIsDeleteCompanyModalShown());
  }

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  const isStatusLoading = deleteCompanyState.status === "loading";

  const modalText = translations.deleteCompanyModal;

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h4>{modalText.headerText}</h4>
        <span onClick={toggleModal}>
          <img src={closeIcon} alt={modalText.closeModalIconAlt} />
        </span>
      </div>
      <p>
        {modalText.confirmationMessage}{" "}
        <span className={classes.orgName}>{selectedData.c_name} ?</span>
      </p>
      <p>{modalText.warningMessage}</p>
      <div className={classes.buttons}>
        <button className={classes.cancelBtn} onClick={toggleModal}>
          {modalText.cancelBtn}
        </button>
        <button
          className={classes.confirmBtn}
          disabled={isStatusLoading}
          onClick={() =>
            dispatch(deleteCompany({ company_id: selectedData.c_id }))
          }
        >
          {isStatusLoading ? <LoadingSpinner /> : modalText.confirmBtn}
        </button>
      </div>
    </div>
  );
}
