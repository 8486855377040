import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const createDepartment = createAsyncThunk("department/createDepartment", async (data, thunkAPI) => {
  const state = thunkAPI.getState();
  const token = state.user.token;

  const departmentData = {
    d_name: data.d_name,
    d_phonenumber: data.d_phonenumber,
    d_address: data.d_address,
    d_cid: data.d_cid,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(departmentData),
  };

  try {
    const response = await fetch(`${apiUrl}/create_department_super`, requestOptions);

    if (!response.ok) {
      let errorMessage = "Network response was not ok";
      try {
        const error = await response.json();
        errorMessage = error.msg || errorMessage;
      } catch (e) {
        errorMessage = await response.text();
      }
      throw new Error(errorMessage || "Unknown error occurred");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Department creation failed:", error);
    return thunkAPI.rejectWithValue(error.message);
  }
});

const createDepartmentSlice = createSlice({
  name: "department",
  initialState: {
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
    department: null,
  },
  reducers: {
    resetCreateDepartmentStatus: (state) => {
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDepartment.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.department = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(createDepartment.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
  },
});

export const { resetCreateDepartmentStatus } = createDepartmentSlice.actions;
export default createDepartmentSlice.reducer;
