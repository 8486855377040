import classes from "./DeleteUser.module.css";
import Xicon from "../../../images/Xicon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { useEscapeKeyClose } from "../../../helperFunctions/customHooks/useEscapeKeyClose";
import { useClickOutsideClose } from "../../../helperFunctions/customHooks/useClickOutsideClose";
import { setIsDeleteUserModalShown } from "../../../redux/globalFnSlice";
import { deleteUser } from "../../../redux/users/deleteUser";
import { LoadingSpinner } from "../../loadingSpinner/LoadingSpinner";
import translations from "../../../en.json";

export function DeleteUser() {
  const { selectedData } = useSelector((state) => state.global);
  const { status } = useSelector((state) => state.deleteUser);

  const dispatch = useDispatch();
  const modalRef = useRef();

  function toggleModal() {
    dispatch(setIsDeleteUserModalShown());
  }

  // Functions for closing the modal when pressing Escape button on keyboard or clicking outside the modal.
  useEscapeKeyClose(toggleModal);
  useClickOutsideClose(modalRef, toggleModal);

  const isStatusLoading = status === "loading";

  const modalText = translations.deleteUserModal;

  return (
    <div className={classes.container} ref={modalRef}>
      <div className={classes.header}>
        <h3>{modalText.headerText}</h3>
        <span>
          <img
            src={Xicon}
            alt={modalText.closeModalIconAlt}
            onClick={toggleModal}
          />
        </span>
      </div>
      <div className={classes.content}>
        <p>
          {modalText.confirmationMessage}
          <span className={classes.user}>
            {selectedData.u_firstname + " " + selectedData.u_lastname}
          </span>
        </p>
        <div className={classes.buttons}>
          <button onClick={toggleModal} className={classes.cancelBtn}>
            {modalText.cancelBtn}
          </button>
          <button
            type="submit"
            className={classes.saveBtn}
            disabled={isStatusLoading}
            onClick={() => dispatch(deleteUser({ u_id: selectedData.u_id }))}
          >
            {isStatusLoading ? <LoadingSpinner /> : modalText.deleteBtn}
          </button>
        </div>
      </div>
    </div>
  );
}
