import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const fetchDepartments = createAsyncThunk(
  "addresses/getDepartments",
  async (data = {}, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    const { filters } = data;

    const queryParams = new URLSearchParams();

    // Append department filter if present and not empty
    if (
      data &&
      data.department_name !== undefined &&
      data.department_name !== ""
    ) {
      queryParams.append("department_name", data.department_name);
    }

    if (data && data.company_id) {
      queryParams.append("company_id", data.company_id);
    }

    // Append search query if present and not empty
    if (data && data.searchQuery !== undefined && data.searchQuery !== "") {
      queryParams.append("department_name", data.searchQuery);
    }

    // Append other filters
    if (filters && filters.sort_by)
      queryParams.append("sort_by", filters.sort_by);
    if (filters && filters.sort_order)
      queryParams.append("sort_order", filters.sort_order);
    if (data && data.page) queryParams.append("page", data.page);
    if (data && data.per_page) queryParams.append("per_page", data.per_page);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apiUrl}/get_company_departments?${queryParams.toString()}`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        let errorText = "";
        try {
          const errorText = await response.text();
          const errorObj = JSON.parse(errorText);
          errorMessage = errorObj.error || errorMessage;
        } catch (e) {
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetching departments failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const departmentsSlice = createSlice({
  name: "departments",
  initialState: {
    departments: [],
    total_count: 0,
    filtered_count: 0,
    department_names: [],
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
  },
  reducers: {
    resetDepartmentsState(state) {
      state.error = null;
      state.status = "idle";
    },
    resetCompanyDepartments(state) {
      state.departments = [];
      state.total_count = 0;
      state.filtered_count = 0;
      state.department_names = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.departments = action.payload.departments;
        state.total_count = action.payload.total_count;
        state.filtered_count = action.payload.filtered_count;
        state.department_names = action.payload.department_names;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchDepartments.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { resetDepartmentsState, resetCompanyDepartments } =
  departmentsSlice.actions;
export default departmentsSlice.reducer;
