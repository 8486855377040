import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../constants";

export const fetchCompanies = createAsyncThunk(
  "addresses/getCompanies",
  async (data, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.token;

    const queryParams = new URLSearchParams();
    if (data && data.company_id) {
      queryParams.append("company_id", data.company_id);
    }

    // Append department filter if present and not empty
    if (data && data.company_name !== undefined && data.company_name !== "") {
      queryParams.append("company_name", data.company_name);
    }

    if (data && data.page) queryParams.append("page", data.page);
    if (data && data.per_page) queryParams.append("per_page", data.per_page);

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `${apiUrl}/get_all_companies?${queryParams.toString()}`,
        requestOptions
      );

      if (!response.ok) {
        let errorMessage = "Network response was not ok";
        let errorText = "";
        try {
          const errorText = await response.text();
          const errorObj = JSON.parse(errorText);
          errorMessage = errorObj.error || errorMessage;
        } catch (e) {
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage || "Unknown error occurred");
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Fetching companies failed:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const companieSlice = createSlice({
  name: "companies",
  initialState: {
    companies: [],
    status: "idle", // 'idle' | 'loading' | 'success' | 'error'
    error: null,
  },
  reducers: {
    resetFetchCompaniesState(state) {
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.status = "success";
        state.error = null;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { resetFetchCompaniesState } = companieSlice.actions;
export default companieSlice.reducer;
